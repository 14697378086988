import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { Tabs, Tab, MenuItem, TextField } from '@mui/material';
import ChatListCell from "./ChatListCell";
import { AppNotification } from "../Notification";
import SearchBar from "./SearchBar";
import btn_contact_add from '../assets/img/menu/ic_add.svg';
import ContactAdd from "./ContactAdd";
import { connect } from 'react-redux';

class ChatList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.isFetching = false;
    this.state = {
      currentContact: this.props.currentContact,
      hasMoreItems: true,
      search: "",
      pendList: [],
      processList: [],
      expiredList: [],
      totalList: [],
      tabIndex: -1,
      popupContactAdd: false,
      followUserId: 'all',
    };
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({
    //   currentContact: nextProps.currentContact,
    // });
    if (nextProps.loading != null)
      this.setState({ loading: nextProps.loading });

    if (nextProps.tabIndex !== this.state.Index) {
      this.setState({ hasMoreItems: true });
    }
  }

  componentDidMount() {}

  componentWillUnmount() {
    const { socket } = this.context;
    socket.unsubscribe("contact");
  }

  renderChatList() {
    const { currentContact, pendList, processList, search } = this.state;
    const { onSelect, currentChannel, data, tabIndex, staffList, channelList } = this.props;
    const { autoReply, defaultPhoneNumberMaskLength, highlightedContacts } = this.context;
    let listItems = [];

    if (data) {
      let list = [];
      list =
        tabIndex !== this.state.tabIndex
          ? tabIndex === 0
            ? this.props.pendList
            : this.props.processList
          : tabIndex === 0
          ? pendList?.slice()
          : processList?.slice();
      //list = tabIndex === 0 ? this.props.pendList?.slice(): this.props.processList?.slice();

      listItems = list?.map((item, index) => (
        <ChatListCell
          key={index}
          item={item}
          defaultPhoneNumberMaskLength={defaultPhoneNumberMaskLength}
          highlightedContacts={highlightedContacts}
          onClick={() => {
            //enable click only if online and not expired
            if (autoReply == false && tabIndex !== 2) {
              onSelect(item);
            }
            this.props.pushNotification({
              title: 'hi',
              'body': 'test notification',
            });
          }}
          currentChannel={currentChannel}
          active={currentContact?.whatsappId === item.whatsappId && currentContact?.channelPhoneNumber === item.channelPhoneNumber}
          channelList={channelList}
          staffList={staffList}
        />
      ));

      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore.bind(this)}
          hasMore={this.state.hasMoreItems}
          initialLoad={true}
          useWindow={false}
          getScrollParent={() => this.scrollParentRef}
          threshold={50}
        >
          {listItems}
        </InfiniteScroll>
      );
    }
  }

  renderExpiredChatList() {
    const { currentContact, expiredList, search } = this.state;
    const { onSelect, currentChannel, data, tabIndex, staffList, channelList } = this.props;
    const { defaultPhoneNumberMaskLength, highlightedContacts } = this.context;

    if (data) {
      let list =
        tabIndex !== this.state.tabIndex
          ? this.props.expiredList
          : expiredList?.slice();
      //let list = this.props.expiredList;

      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadExpiredMore.bind(this)}
          hasMore={this.state.hasMoreItems}
          initialLoad={true}
          useWindow={false}
          getScrollParent={() => this.scrollParentRef}
          threshold={50}
        >
          {list?.map((item, index) => (
            <ChatListCell
              key={index}
              item={item}
              defaultPhoneNumberMaskLength={defaultPhoneNumberMaskLength}
              highlightedContacts={highlightedContacts}
              isExpired={true}
              onClick={() => onSelect(item, true)}
              currentChannel={currentChannel}
              currentContact={currentContact}
              active={currentContact?.whatsappId === item.whatsappId && currentContact?.channelPhoneNumber === item.channelPhoneNumber}
              staffList={staffList}
              channelList={channelList}
            />
          ))}
        </InfiniteScroll>
      );
    }
  }

  updateChatList(res, isExpired, search) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const channelList = userInfo?.channels ?? [];
    const { tabIndex, expiredList } = this.props;

    let newFullList = [];

    if (!userInfo?.administrator) {
      let channelPhoneNumberList = [];
      for (var i = 0; i < channelList.length; i++) {
        channelPhoneNumberList.push(channelList[i].channelPhoneNumber.trim());
      }

      newFullList = res?.slice();
      newFullList = newFullList?.filter((contact) =>
        channelPhoneNumberList.includes(
          contact.messages[0].channelPhoneNumber.trim()
        )
      );
    } else {
      newFullList = res?.slice();
    }

    let newPendList = newFullList.filter((x) =>
      x.messages?.some(
        (y) =>
          y.unreadCount > 0 ||
          y.lastMessage?.fromWhatsappId !== null ||
          y.lastMessage?.isAutoReply == true
      )
    );
    let newProcessList = newFullList.filter((x) =>
      x.messages?.some(
        (y) =>
          y.unreadCount == 0 &&
          y.lastMessage?.isAutoReply != true &&
          y.lastMessage?.fromWhatsappId == null
      )
    );
    let newExpiredList = expiredList.slice();

    if (isExpired === "1") {
      newExpiredList = newFullList.slice();
    }

    if (res.length == 0) {
      // console.log('No More')
      this.setState({
        tabIndex: tabIndex,
        totalList: newFullList,
        pendList: newPendList,
        processList: newProcessList,
        expiredList: newExpiredList,
        hasMoreItems: false,
        search: search,
      });
    } else {
      this.setState({
        tabIndex: tabIndex,
        totalList: newFullList,
        pendList: newPendList,
        processList: newProcessList,
        expiredList: newExpiredList,
        search: search,
      });
    }
  }

  searchChatList({ keywords, isExpired, nextFollowUserId = null }) {
    const { socket } = this.context;
    //this.props.updateIsSearching(keywords.length>0);
    socket.emit(
      "getContacts",
      {
        keywords: keywords,
        expired: isExpired,
        limit: process.env.REACT_APP_CONTACT_GET_LIMIT,
        followUserId: nextFollowUserId ? nextFollowUserId !== 'all' ? nextFollowUserId : null : this.state.followUserId !== 'all' ? this.state.followUserId : null,
      },
      (err, res) => {
        this.isFetching = false;
        if (err) {
          alert(err);
        } else {
          this.updateChatList(res, isExpired, keywords);
        }
      }
    );
  }

  updateFilterFollowUserId(followUserId) {
    this.setState({ followUserId: followUserId});
    this.searchChatList({
      keywords: this.state.search,
      isExpired: this.props.tabIndex === 2 ? '1' : '0',
      nextFollowUserId: followUserId
    });
  }

  loadMore() {
    const { socket } = this.context;
    const { hasMoreItems, pendList, processList, totalList, search } =
      this.state;
    const { tabIndex } = this.props;

    if (!this.isFetching && hasMoreItems) {
      this.isFetching = true;

      let list = [];

      if (tabIndex !== this.state.tabIndex) {
        if (this.props.data.length > 0) {
          const firstPendList = this.props.pendList;
          const firstProcessList = this.props.processList;
          const firstTotalList = this.props.data;
          this.isFetching = false;
          this.setState({
            tabIndex: tabIndex,
            totalList: firstTotalList,
            pendList: firstPendList,
            processList: firstProcessList,
          });
        }
      } else {
        list = tabIndex === 0 ? pendList?.slice() : processList?.slice();
      }

      if (
        list.length > 0 ||
        (tabIndex === 0 && this.props.pendList.length === 0) ||
        (tabIndex === 1 && this.props.processList.length === 0)
      ) {
        // Load channel list from user info
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const channelList = userInfo?.channels ?? [];

        if (totalList.length > 0) {
          let data = totalList
            ? {
                beforeId: totalList[totalList.length - 1].messages[0].id,
                expired: "0",
                limit: process.env.REACT_APP_CONTACT_GET_LIMIT,
                keywords: search,
                followUserId: this.props.filterFollowUserId !== 'all' ? this.props.filterFollowUserId : null ?? null,
              }
            : null;

          socket.emit("getContacts", data, (err, res) => {
            this.isFetching = false;
            if (err) {
              alert(err);
            } else {
              if (res.length == 0) {
                // console.log('No More')
                this.setState({ hasMoreItems: false });
              } else {
                let newFullList = [];

                if (!userInfo?.administrator) {
                  let channelPhoneNumberList = [];
                  for (var i = 0; i < channelList.length; i++) {
                    channelPhoneNumberList.push(
                      channelList[i].channelPhoneNumber.trim()
                    );
                  }

                  newFullList = res?.slice();
                  newFullList = newFullList?.filter((contact) =>
                    channelPhoneNumberList.includes(
                      contact.messages[0].channelPhoneNumber.trim()
                    )
                  );
                } else {
                  newFullList = res?.slice();
                }

                let newPendList = newFullList.filter((x) =>
                  x.messages?.some(
                    (y) =>
                      y.unreadCount > 0 ||
                      y.lastMessage?.fromWhatsappId !== null ||
                      y.lastMessage?.isAutoReply == true
                  )
                );
                let newProcessList = newFullList.filter((x) =>
                  x.messages?.some(
                    (y) =>
                      y.unreadCount == 0 &&
                      y.lastMessage?.isAutoReply != true &&
                      y.lastMessage?.fromWhatsappId == null
                  )
                );
                this.setState({
                  totalList: [...totalList, ...newFullList],
                  pendList: [...pendList, ...newPendList],
                  processList: [...processList, ...newProcessList],
                  hasMoreItems: res.length < process.env.REACT_APP_CONTACT_GET_LIMIT ? false : true,
                });
                this.props.appendContacts(res);
              }
            }
          });
        } else {
          if (tabIndex === this.state.tabIndex) {
            let newFullList = [];

            if (!userInfo?.administrator) {
              let channelPhoneNumberList = [];

              for (var i = 0; i < channelList.length; i++) {
                channelPhoneNumberList.push(
                  channelList[i].channelPhoneNumber.trim()
                );
              }

              newFullList = totalList?.slice();
              newFullList = newFullList?.filter((contact) =>
                channelPhoneNumberList.includes(
                  contact.messages[0].channelPhoneNumber.trim()
                )
              );
            } else {
              newFullList = totalList?.slice();
            }

            let newPendList = newFullList.filter((x) =>
              x.messages?.some(
                (y) =>
                  y.unreadCount > 0 ||
                  y.lastMessage?.fromWhatsappId !== null ||
                  y.lastMessage?.isAutoReply == true
              )
            );
            let newProcessList = newFullList.filter((x) =>
              x.messages?.some(
                (y) =>
                  y.unreadCount == 0 &&
                  y.lastMessage?.isAutoReply != true &&
                  y.lastMessage?.fromWhatsappId == null
              )
            );

            this.setState({
              pendList: [...newPendList],
              processList: [...newProcessList],
            });
          }
        }
      }
    }
  }

  loadExpiredMore() {
    const { socket } = this.context;
    const {
      hasMoreItems,
      processList,
      pendList,
      expiredList,
      totalList,
      search,
    } = this.state;
    const { tabIndex } = this.props;

    if (!this.isFetching && hasMoreItems) {
      this.isFetching = true;

      let list = [];

      if (tabIndex !== this.state.tabIndex) {
        if (this.props.expiredList.length > 0) {
          const firstExpiredList = this.props.expiredList;
          const firstTotalList = this.props.expiredList;
          this.isFetching = false;
          this.setState({
            tabIndex: tabIndex,
            totalList: firstTotalList,
            expiredList: firstExpiredList,
          });
        }
      } else {
        list = expiredList?.slice();
      }

      if (list.length > 0 || this.props.expiredList.length === 0) {
        // Load channel list from user info
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const channelList = userInfo?.channels ?? [];

        if (totalList.length > 0) {
          let data = totalList
            ? {
                beforeId: totalList[totalList.length - 1].messages[0].id,
                expired: "1",
                limit: process.env.REACT_APP_CONTACT_GET_LIMIT,
                keywords: search,
                followUserId: this.props.filterFollowUserId !== 'all' ? this.props.filterFollowUserId : null ?? null,
              }
            : null;

          socket.emit("getContacts", data, (err, res) => {
            this.isFetching = false;
            if (err) {
              alert(err);
            } else {
              if (res.length == 0) {
                // console.log('No More')
                this.setState({ hasMoreItems: false });
              } else {
                let newFullList = [];

                if (!userInfo?.administrator) {
                  let channelPhoneNumberList = [];
                  for (var i = 0; i < channelList.length; i++) {
                    channelPhoneNumberList.push(
                      channelList[i].channelPhoneNumber.trim()
                    );
                  }

                  newFullList = res?.slice();
                  newFullList = newFullList?.filter((contact) =>
                    channelPhoneNumberList.includes(
                      contact.messages[0].channelPhoneNumber.trim()
                    )
                  );
                } else {
                  newFullList = res?.slice();
                }

                // let newExpiredList = newFullList.filter((x) =>
                //   x.messages?.some((y) => y.lastMessage.fromWhatsappId == null)
                // );
                let newExpiredList = newFullList;

                this.setState({
                  totalList: [...totalList, ...newFullList],
                  expiredList: [...expiredList, ...newExpiredList],
                  hasMoreItems: res.length < process.env.REACT_APP_CONTACT_GET_LIMIT ? false : true,
                });
                this.props.appendContacts(res);
              }
            }
          });
        } else {
          if (tabIndex === this.state.tabIndex) {
            let newFullList = [];

            if (!userInfo?.administrator) {
              let channelPhoneNumberList = [];

              for (var i = 0; i < channelList.length; i++) {
                channelPhoneNumberList.push(
                  channelList[i].channelPhoneNumber.trim()
                );
              }

              newFullList = totalList?.slice();
              newFullList = newFullList?.filter((contact) =>
                channelPhoneNumberList.includes(
                  contact.messages[0].channelPhoneNumber.trim()
                )
              );
            } else {
              newFullList = totalList?.slice();
            }

            let newExpiredList = newFullList.filter((x) =>
              x.messages?.some((y) => y.lastMessage.fromWhatsappId == null)
            );

            this.setState({
              expiredList: [...newExpiredList],
            });
          }
        }
      }
    }
  }

  renderFilterFollowUser() {
    const staffItems = this.props.staffList?.map((item, index) =>
    <MenuItem key={`filter_followUser_${item.id}`} value={item.id} style={{fontSize:14}}>
      {item.name}
    </MenuItem>
    )
    return (
    <TextField
    id="filter-followUsers"
    select
    value={this.state.followUserId}
    onChange={event => {
      this.updateFilterFollowUserId(event.target.value);
      //this.props.updateFilterFollowUserId(event.target.value);
    }
    }
    >
    <MenuItem key={`filter_followUser_all`} value={'all'} style={{fontSize:14}}>
      所有
    </MenuItem>
    {staffItems}
    </TextField>
    );
  }

  render() {
    const { tabIndex, onUpdateTabIndex, updateBackdrop, contactListIsFetching } = this.props;
    const { autoReply } = this.context;

    let isExpired = tabIndex !== 2 ? "0" : "1";

    return (
      <>
      <div id="chat-list" className={autoReply === true ? "disable" : ""}>
        <Tabs
          value={tabIndex}
          textColor="inherit"
          onChange={(event, newValue) => {
            onUpdateTabIndex(newValue);

            // Clear search keyword
            this.setState({ search: "", followUserId: 'all' });
          }}
        >
          <Tab label="未解答" disabled={autoReply === true} />
          <Tab label="解答中" disabled={autoReply === true} />
          <Tab label="已結束" disabled={autoReply === true} />
        </Tabs>
        <div className='followUser-container'>
          <label className='input-label' htmlFor='filter-followUsers'>負責人:</label>
          {this.renderFilterFollowUser()}
        </div>
        <div className="search-container">
          <SearchBar
            keyword={this.state.search}
            onChange={(value) => this.setState({ search: value })}
            onKeyDown={(value) =>
              this.searchChatList({ keywords: value, isExpired: isExpired })
            }
          />
          <div className="contact-add">
            <img src={btn_contact_add} onClick={() => this.setState({popupContactAdd: true})} />
          </div>
        </div>
        <div
          style={{ width: "100%", overflow: "auto" }}
          ref={(ref) => (this.scrollParentRef = ref)}
        >
          {this.state.loading === true && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6px",
              }}
            >
              <div className="loader"></div>
            </div>
          )}
          {tabIndex == 2 ? this.renderExpiredChatList() : this.renderChatList()}
        </div>
      </div>
      <ContactAdd
        open={this.state.popupContactAdd}
        onClose={() => this.setState({popupContactAdd: false})}
        channels={this.props?.channels}
        context={this.context}
        updateBackdrop={updateBackdrop}
        searchChatList={this.searchChatList.bind(this)}
        isExpired={isExpired}
      />
      </>
    );
  }
}

ChatList.contextTypes = {
  socket: PropTypes.object.isRequired,
  autoReply: PropTypes.string,
  defaultPhoneNumberMaskLength: PropTypes.string,
  highlightedContacts: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => {
  return { channels: state.reducer.channels }
}

export default connect(mapStateToProps)(AppNotification(ChatList));
